@import url("https://fonts.googleapis.com/css2?family=Exo:wght@100;200;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Exo", sans-serif;
  scroll-behavior: smooth;
}
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #ec4b21 #e8e8e8;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
    height: 9px;
  }

  *::-webkit-scrollbar-track {
    background: #e8e8e8;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ec4b21;
    border-radius: 10px;
    border: 1px none #ffffff;
  }
  /*  */

.all {
  max-width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  background-color: #fefcfb;
  flex: 1 0 auto;
}
.staticPage {
  background-color: #fefcfb;

  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.hidden-datepicker {
  border: none;
}

.ant-picker-input {
  visibility: hidden;
}